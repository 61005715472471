import { Injectable } from '@angular/core';

import * as moment from 'moment';
moment.locale('pt-bt');

/** Configurando libs para criação e impressão do PDF do pedido */
const pdfMakeX = require('pdfmake/build/pdfmake.js');
const pdfFontsX = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;

import * as pdfMake from 'pdfmake/build/pdfmake';

import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { SessionStorageKey } from 'src/app/shared/enums/session-storage-key.enum';

import { OrderResponseApiModel } from '../models/order-response-api.model';
import { PrinterConfiguration } from '../../manage-settings/models/printer-configuration.model';

import {
  getKitchenTemplate,
  getMotoboyTemplate,
} from '../templates/print-templates';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  constructor(private sessionStorageService: SessionStorageService) {}

  /**
   * @description Recuperar configurações de impressão do usuário
   *
   * @author Darllinson Azevedo
   *
   * @returns Config. de impressão, caso não seja encontrada, retorna `null`
   */
  getPrinterConfig(): PrinterConfiguration | null {
    const config = this.sessionStorageService.getItem(
      SessionStorageKey.PRINTER_CONFIG
    );

    if (config) {
      return JSON.parse(config);
    } else {
      return null;
    }
  }

  /**
   * @description Salvar configuração de impressão de pedidos do usuário
   *
   * @author Darllinson Azevedo
   *
   * @param config Payload dos dados do formulário
   */
  savePrinterConfig(config: PrinterConfiguration): void {
    this.sessionStorageService.storage(
      SessionStorageKey.PRINTER_CONFIG,
      JSON.stringify(config)
    );
  }

  /**
   * @description Imprimir comprovante do pedido do cliente
   *
   * @author Darllinson Azevedo
   *
   * @param order Payload com os dados do pedido
   */
  printOrder(order: OrderResponseApiModel, type: 'kitchen' | 'motoboy'): void {
    const config = this.getPrinterConfig();
    let template: any;

    switch (config.paperSize) {
      case '58':
        template =
          type === 'kitchen'
            ? getKitchenTemplate('58', order)
            : getMotoboyTemplate('58', order);
        break;
      case '72':
        template =
          type === 'kitchen'
            ? getKitchenTemplate('72', order)
            : getMotoboyTemplate('72', order);
        break;
      case '80':
        template =
          type === 'kitchen'
            ? getKitchenTemplate('80', order)
            : getMotoboyTemplate('80', order);
        break;
    }

    pdfMake.createPdf(template).print();
  }

  /**
   * @description Salvar PDF com os dados do pedido
   *
   * @author Darllinson Azevedo
   *
   * @param order Payload com os dados do pedido
   */
  savePdf(order: OrderResponseApiModel): void {
    const config = this.getPrinterConfig();
    let template: any;

    switch (config.paperSize) {
      case '58':
        template = getKitchenTemplate('58', order)
        break;
      case '72':
        template = template = getKitchenTemplate('72', order)
        break;
      case '80':
        template = template = getKitchenTemplate('80', order)
        break;
    }

    pdfMake
      .createPdf(template)
      .download(`pedido-${order.orderNumber}-temrango.pdf`);
  }
}
